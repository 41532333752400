import React, { FC } from "react";
import Layout from "../components/layout/Layout";
import Breadcrumb from "../components/layout/Breadcrumb";
import { Helmet } from "react-helmet";

const title = "Politica de utilizare a cookie-urilor";

const PoliticaCookies: FC = () => (
    <Layout>
        <Helmet title={title} />
        <Breadcrumb title={title} />

        <section className="page-area section-default section-padding-100">
            <div className="container">
                <div className="row">
                    <div className="col">
                        <p>Folosim cookie-uri strict necesare pentru funcționalitatea site-ului.</p>

                        <p>Cookie-urile sunt fișiere text de mici dimensiuni pe care site-urile le salvează pe device-ul care a inițiat navigarea. Ele sunt procesate de către browser-ul web de pe care se face navigarea. Sunt fișiere inofensive, dar cruciale pentru funcționalitatea site-urilor. În general, cookie-urile pot fi ușor vizualizate și șterse.</p>

                        <p>Acest site foloseşte cookie-uri strict necesare pentru implementarea CAPTCHA pe formulare. CAPTCHA oferă protecție împotriva accesului neautorizat, permițând accesul exclusiv oamenilor. Cookie-urile sunt strict necesare pentru funcționarea site-ului, care nu ar funcționa corespunzător în lipsa lor. Aceste cookie-uri nu păstrează date cu caracter personal.</p>

                        <table className="table table-responsive table-sm mt-5">
                            <thead className="thead-light">
                                <tr>
                                    <th>Nume</th>
                                    <th>Domeniu</th>
                                    <th>Tip</th>
                                    <th>Durata</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>_GRECAPTCHA</td>
                                    <td>www.recaptcha.net</td>
                                    <td>http only, secure</td>
                                    <td>6 luni</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </section>
    </Layout >
);

export default PoliticaCookies;
